import React from "react"
import styled from "styled-components"
import loadable from "@loadable/component"
import { FormContextProvider } from "../../contexts/formContext"
import { FormInputState } from "../../utils/validations"
import { FormZone } from "../../ts/interfaces"
import { fas } from "@fortawesome/free-solid-svg-icons"

const FooterForm = loadable(() => import("./NewsletterForm"))

const FormTitle = styled.h3<{ compress?: boolean }>`
  ${({ compress }) =>
    compress
      ? `
  margin-top: 0;
  margin-bottom: 0.5rem;
  `
      : "margin-bottom: 0.5rem;"}
`
export const practices = [
  "Counselling",
  "Coaching",
  "Biofeedback",
  "Energy Work",
  "TCM",
  "Chiropractic",
  "Naturopath",
]

const NewsletterSignup = ({
  form,
  compress,
  id,
}: {
  form: FormZone
  compress: boolean
  id?: string
}) => {
  const { emailSignupNameOptions } = form
  const initialFormInput: Record<string, FormInputState> = {
    firstName: {
      value: "",
      label: "First Name",
    },
    lastName: {
      value: "",
      label: "Last Name",
    },
    email: {
      value: "",
      label: "Email",
      validation: [
        { type: "required" },
        { type: "email" },
        { type: "min", value: 3 },
        { type: "max", value: 150 },
      ],
    },
    phone: {
      value: "",
      label: "Phone Number",
    },
    organization: {
      value: "",
      label: "Organization",
    },
    city: {
      value: "",
      label: "City",
    },
    province: {
      value: "",
      label: "State / Province",
    },
    country: {
      value: "",
      label: "Country",
    },
    comments: {
      value: "",
      label: "Comments",
    },
  }

  if (emailSignupNameOptions && emailSignupNameOptions === "showAndRequire") {
    initialFormInput.firstName.validation = [
      { type: "required" },
      { type: "min", value: 2 },
      { type: "max", value: 150 },
    ]
    initialFormInput.lastName.validation = [
      { type: "required" },
      { type: "min", value: 2 },
      { type: "max", value: 150 },
    ]
  }

  for (const practice of practices) {
    const key = `practice${practice.replace(" ", "")}`

    initialFormInput[key] = {
      type: "checkbox",
      value: false,
      label: practice,
    }
  }

  return (
    <FormContextProvider initialInputs={initialFormInput}>
      {form.formTitle && (
        <FormTitle className="gradientText" compress={compress}>
          {form.formTitle}
        </FormTitle>
      )}
      {form.formSubTitle && <p>{form.formSubTitle}</p>}
      <FooterForm form={form} id={id} compress={compress} />
    </FormContextProvider>
  )
}

export default NewsletterSignup
