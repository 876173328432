import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "../ui/Icon"
import { Button } from "../ui/Button"
import Carousel from "../ui/Carousel"
import { Testimonial } from "../../ts/interfaces"
import { devices } from "../config/breakpoints"
import Quote from "../ui/Quote"
import { Hidden } from "@material-ui/core"

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 3rem;
  padding: 1rem 2rem;
  margin-left: 10%;
  margin-right: 10%;
  position: relative;
  min-height: 120px;
  overflow: visible;
  background: var(--color-gradient);
  color: var(--color-white);
  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 1rem;
  }
`

const Authors = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 0.5rem;
  margin-bottom: 0.5em;
`

const AuthorContainer = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  margin-top: 1rem;
  margin-right: -0.25rem;
  margin-left: -0.5rem;
  transition: width 1s ease;
  @media (max-width: 600px) {
    margin-right: 0rem;
    width: 3rem;
  }

  &::before {
    position: relative;
    content: " ";
    margin: 1rem 1rem 0;
    @media (max-width: 600px) {
      display: none;
    }
  }
`

const AuthorWrapper = styled.div<{ selected: boolean; hideMobile?: boolean }>`
  overflow: hidden;
  white-space: pre-line;
  transition: opacity 1s ease, width 1s ease;
  width: ${({ selected }) => (selected ? `250px` : `0`)};
  opacity: ${({ selected }) => (selected ? `1` : `0`)};
  display: ${({ hideMobile }) => (hideMobile ? `inline-block` : `none`)};
  @media (max-width: 600px) {
    display: ${({ hideMobile }) => (hideMobile ? `none` : `inline-block`)};
  }
`

const AuthorInnerWrapper = styled.div<{ selected: boolean }>`
  white-space: pre-line;
  width: 250px !important;
`

const AvatarContainerSm = styled.div<{ selected: boolean }>`
  height: 0.75rem !important;
  width: 0.75rem !important;
  border-radius: 50%;
  background-color: var(--color-purple);
  cursor: pointer;
  transition: all 0.5s ease;
  opacity: ${({ selected }) => (selected ? `1` : `0.45`)};
  box-shadow: ${({ selected }) =>
    selected
      ? `0px 0px 2px 2px var(--color-purple-light)`
      : `0px 0px 8px 3px transparent`};
  &:hover {
    opacity: 1;
    background-color: var(--color-teal);
    box-shadow: ${({ selected }) =>
      selected
        ? `0px 0px 2px 2px var(--color-teal-light)`
        : `0px 0px 8px 3px var(--color-teal-light)`};
  }
`

const AvatarContainer = styled.div`
  height: 132px;
  width: 132px;
  border-radius: 50%;
  position: absolute;
  left: 5%;
  top: -1.75rem;
  z-index: 100;
  background-color: white;
  @media ${devices.mobile} {
    height: 90px;
    width: 90px;
  }
  @media (max-width: 600px) {
    left: 50%;
    margin-left -45px;
    top:-60px;
  }
`
const StyledAvatarImg = styled(props => <GatsbyImage {...props} />)`
  height: 132px;
  width: 132px;
  border-radius: 50%;
  @media ${devices.mobile} {
    height: 90px !important;
    width: 90px !important;
  }
`

const Author = styled.p`
  margin-top: 5px;
  margin-left: 13px;
  color: var(--color-grey-5);
  font-weight: 600;
  line-height: 1rem;
  @media (max-width: 600px) {
    color: var(--color-white);
  }
`
const Company = styled.p`
  color: var(--color-grey-5);
  margin-left: 13px;
  letter-spacing: 0;
  font-size: 0.8rem;
  line-height: 1rem;
  @media (max-width: 600px) {
    color: var(--color-white);
  }
`

const ReadMoreLessText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: var(--font-family-header);
  font-weight: bold;
  svg {
    margin-top: 8px;
  }
  @media (max-width: 600px) {
    margin-top: 16px;
  }
`

const QuoteWrapper = styled.div<{ cursorPointer: boolean }>`
  cursor: ${({ cursorPointer }) => (cursorPointer ? `pointer` : `initial`)};
`

const QuoteBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

const TestimonialsSplash = ({
  sectionId,
  testimonials,
  startIndex,
  backgroundColor,
}: {
  sectionId: string
  testimonials: Testimonial[]
  startIndex: number
  backgroundColor: string | undefined
}) => {
  const [index, setIndex] = useState(startIndex)
  const [expanded, setExpanded] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { id, avatar, author, company, quote } = testimonials[index]

  const handleClickPrevious = () => {
    setIsLoading(true)
    setIndex(previous => previous - 1)
  }

  const handleClickNext = () => {
    setIsLoading(true)
    setIndex(previous => previous + 1)
  }

  // Loading state use to prevent users from turboclicking the carousel buttons and messing up state
  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [index])

  return (
    <div style={{ position: "relative" }}>
      <AvatarContainer>
        {avatar && (
          <StyledAvatarImg
            alt={(avatar && avatar.alternativeText) || author}
            image={
              avatar &&
              avatar.imageFile &&
              avatar.imageFile.childImageSharp.gatsbyImageData
            }
          />
        )}

        {!avatar && (
          <Icon
            backgroundColor={backgroundColor ? backgroundColor : undefined}
            iconType="Solid"
            iconName="user-circle"
            size="8x"
          />
        )}
      </AvatarContainer>
      <ItemContainer>
        <Carousel
          autoPlay={false}
          index={index}
          indicators={false}
          animation="fade"
          onChange={idx => setIndex(idx)}
        >
          {testimonials.map((item: Testimonial) => {
            const { id, quote } = item

            const truncate = quote.length > 155

            return (
              <QuoteWrapper
                key={id}
                onClick={() => (truncate ? setExpanded(!expanded) : null)}
                cursorPointer={truncate}
              >
                <Quote
                  color="var(--color-white)"
                  fontStyle="normal"
                  margin="0 0 0 3.5rem"
                  expanded={expanded}
                  truncate={truncate}
                >
                  {quote}{" "}
                </Quote>

                <QuoteBottomWrapper>
                  {truncate && (
                    <ReadMoreLessText id="read-more-less">
                      {expanded ? "READ LESS" : "READ MORE"}
                      <Icon
                        iconType="Solid"
                        size="lg"
                        brightColor={true}
                        iconName={!expanded ? "chevron-down" : "chevron-up"}
                      />
                    </ReadMoreLessText>
                  )}
                  <AuthorWrapper selected={true} hideMobile={false}>
                    <AuthorInnerWrapper selected={true}>
                      <Author>{author}</Author>
                      {company && <Company>{company}</Company>}
                    </AuthorInnerWrapper>
                  </AuthorWrapper>
                </QuoteBottomWrapper>
              </QuoteWrapper>
            )
          })}
        </Carousel>
      </ItemContainer>
      {testimonials.length > 1 && (
        <Authors>
          <Button
            onClick={handleClickPrevious}
            type="textSecondary"
            icon="chevron-left"
            disabled={index <= 0 || isLoading}
            size="hero"
            sectionId={sectionId}
            id="left"
          />
          {testimonials.map((item: Testimonial, i: number) => {
            const { id, avatar, author, company } = item
            return (
              <div style={{ position: "relative" }}>
                <AuthorContainer
                  key={i}
                  onClick={() => setIndex(i)}
                  selected={i === index}
                >
                  <AvatarContainerSm selected={i === index}></AvatarContainerSm>
                  <AuthorWrapper selected={i === index} hideMobile={true}>
                    <AuthorInnerWrapper selected={i === index}>
                      <Author>{author}</Author>
                      {company && <Company>{company}</Company>}
                    </AuthorInnerWrapper>
                  </AuthorWrapper>
                </AuthorContainer>
              </div>
            )
          })}
          <Button
            onClick={handleClickNext}
            type="textSecondary"
            icon="chevron-right"
            size="hero"
            sectionId={sectionId}
            id="right"
            disabled={index >= testimonials.length - 1 || isLoading}
          />
        </Authors>
      )}
      {testimonials.length === 1 && (
        <Hidden xsDown>
          <Authors>
            <AuthorContainer selected={true}>
              <AuthorWrapper selected={true} hideMobile={true}>
                <AuthorInnerWrapper selected={true}>
                  <Author>{author}</Author>
                  {company && <Company>{company}</Company>}
                </AuthorInnerWrapper>
              </AuthorWrapper>
            </AuthorContainer>
          </Authors>
        </Hidden>
      )}
    </div>
  )
}

export default TestimonialsSplash
