import React, { useEffect, useState } from "react"
import CheckoutStep from "./Step"
import { StepInterface } from "../ts/interfaces"
import styled from "styled-components"
import { devices } from "./config/breakpoints"

const StepContainer = styled.div`
  display: flex;
  justify-content: space-around;

  margin-bottom: 2rem;
  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
`

const StepsComponent = ({
  steps,
}: // onClick,
{
  steps: StepInterface[]
  // onClick?: (index: number) => void
}) => {
  const [rsteps, setSteps] = useState<StepInterface[]>(steps)
  useEffect(() => {
    setSteps(steps.filter(s => !s.skip))
  }, [steps])

  return (
    <StepContainer>
      {rsteps.map((step, index) =>
        step.skip === true ? null : (
          <CheckoutStep
            key={index}
            step={{
              ...step,
              stepNumber: index + 1,
              // onClick: () => onClick && onClick(index),
            }}
            last={index === rsteps.length - 1}
          />
        )
      )}
    </StepContainer>
  )
}

export default StepsComponent
