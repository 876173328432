import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Markdown from "markdown-to-jsx"
import { SectionTitle } from "../ui/Title"
import PaddedContainer from "../ui/PaddedContainer"
import Grid from "@material-ui/core/Grid"
import { Card, Image, SectionCore } from "../../ts/interfaces"
import Icon from "../ui/Icon"
import { Button } from "../ui/Button"
import ProductPrice from "../products/ProductPrice"
import { SectionDividor } from "../ui/line"

interface FeatureSection {
  id: string
  description: string
  image: Image
  cards: Card[]
  core: SectionCore
}

const ListItem = styled.div<{ marginBottom: boolean }>`
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "32px" : "initial")};
`

const IconContainer = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ImageContainer = styled(props => <GatsbyImage {...props} />)`
  width: 90%;
  height: 100%;
  margin: 3rem 0 1rem 0;
`

const FeatureImage = styled(props => <GatsbyImage {...props} />)`
  margin: 3rem 0 1rem 0;
`

const FeatureDescription = styled(Markdown)`
  display: flex;
  flex-direction: column;
  & ul {
    list-style-type: circle;
  }
`

const Features = (section: FeatureSection) => {
  const { description, image, cards } = section
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    backgroundColor,
    animate,
    underlineTitle,
    titleAlign,
    smallSubTitle,
  } = section.core

  return (
    <PaddedContainer>
      <SectionDividor isTop={true} core={section.core} />
      <SectionTitle
        title={title}
        titleColor={titleColor}
        subtitle={subtitle}
        subtitleColor={subtitleColor}
        animate={animate}
        titleAlign={titleAlign}
        underlineTitle={underlineTitle}
        smallSubTitle={smallSubTitle}
      />
      <Grid container>
        <Grid item xs={12} sm={6}>
          {description && <p data-aos={animate && "fade-up"}>{description}</p>}
          {cards.map((card: Card, idx: number, arr: Card[]) => {
            const {
              id,
              title,
              iconType,
              iconName,
              description,
              media,
              button,
              productPriceDisplay,
              productDisplayEnum,
            } = card
            return (
              <div key={id}>
                <ListItem
                  data-aos={animate && "fade-right"}
                  marginBottom={idx !== arr.length - 1}
                >
                  {iconType && iconName && (
                    <div>
                      <IconContainer>
                        <Icon
                          backgroundColor={
                            backgroundColor ? backgroundColor : "white"
                          }
                          iconType={iconType}
                          iconName={iconName}
                          size="2x"
                          brightColor={true}
                        />
                      </IconContainer>
                    </div>
                  )}

                  <div>
                    <h3>{title}</h3>
                    {description && (
                      <FeatureDescription data-aos={animate && "fade-up"}>
                        {description}
                      </FeatureDescription>
                    )}
                    {productPriceDisplay && (
                      <ProductPrice
                        priceObj={productPriceDisplay}
                        productDisplayEnum={productDisplayEnum}
                        small={true}
                      />
                    )}
                    {button && (
                      <div style={{ marginTop: "1rem" }}>
                        {" "}
                        <Button
                          key={button.id}
                          {...button}
                          sectionId={`${id}_feature`}
                          id={button.id}
                        />
                      </div>
                    )}
                  </div>
                </ListItem>

                {media && (
                  <FeatureImage
                    data-aos={animate && "fade-right"}
                    image={media.imageFile.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    alt={media.alternativeText}
                  />
                )}
              </div>
            )
          })}
        </Grid>
        {image && (
          <Grid item xs={12} sm={6}>
            <FlexContainer>
              <ImageContainer
                data-aos={animate && "zoom-in"}
                image={image.imageFile.childImageSharp.gatsbyImageData}
                objectFit="contain"
                alt={image.alternativeText}
              />
            </FlexContainer>
          </Grid>
        )}
      </Grid>
      <SectionDividor isTop={false} core={section.core} />
    </PaddedContainer>
  )
}

export default Features
