import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Icon from "../ui/Icon"
import PaddedContainer from "../ui/PaddedContainer"
import Carousel from "../ui/Carousel"
import { Testimonial, SectionCore, Image } from "../../ts/interfaces"
import LogoCarousel from "../ui/LogoCarousel"

interface Props {
  id: string
  images: Image[]
  backgroundColor: string
  marginTop: number
  marginBottom: number
  paddingTop: number
  paddingBottom: number
}

interface SectionProps {
  $bgColor: string
  $mt: number
  $mb: number
  $pt: number
  $pb: number
}

const Section = styled.section<SectionProps>`
  background-color: ${props =>
    props.$bgColor === "lightGrey" ? "var(--color-grey-1)" : "#fff"};
  margin-top: ${props => props.$mt}px;
  margin-bottom: ${props => props.$mb}px;
  padding-top: ${props => props.$pt}px;
  padding-bottom: ${props => props.$pb}px;
`

const CarouselSection = ({
  id,
  images,
  backgroundColor,
  marginTop,
  marginBottom,
  paddingTop,
  paddingBottom,
}: Props) => {
  const mt = marginTop ? marginTop : 0
  const mb = marginBottom ? marginBottom : 0
  const pt = paddingTop ? paddingTop : 0
  const pb = paddingBottom ? paddingBottom : 0

  return (
    <Section $bgColor={backgroundColor} $mt={mt} $mb={mb} $pt={pt} $pb={pb}>
      <LogoCarousel slides={images} />
    </Section>
  )
}

export default CarouselSection
