import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

import { SectionDividor } from "../ui/line"
import { SectionTitle } from "../ui/Title"
import { Card, SectionCore } from "../../ts/interfaces"

import PaddedContainer from "../ui/PaddedContainer"
import CardElm from "../ui/Card"

interface CardGrid {
  id: string
  card: Card[]
  core: SectionCore
  columns?: number
  maxWidth?: number
}

const CardSection = styled.div<{ center?: boolean }>`
  text-align: ${({ center }) => (center === false ? "left" : "center")};
`

const ContainerWidth = styled(props => <div {...props} />)`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : "")}
  margin: 0 auto;
`

const CardGrid = (section: CardGrid) => {
  const { id, card, columns } = section
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    backgroundColor,
    animate,
    titleAlign,
    underlineTitle,
    smallSubTitle,
  } = section.core

  let gridSize = card.length % 2 === 0 ? 6 : 4
  if (card.length == 4) {
    gridSize = 3
  }
  if (columns) {
    gridSize = Math.floor(12 / columns)
  }

  return (
    <CardSection center={gridSize !== 12}>
      <PaddedContainer>
        <ContainerWidth maxWidth={section.maxWidth}>
          <SectionDividor isTop={true} core={section.core} />
          <SectionTitle
            title={title}
            titleColor={titleColor}
            subtitle={subtitle}
            subtitleColor={subtitleColor}
            animate={animate}
            titleAlign={titleAlign}
            underlineTitle={underlineTitle}
            smallSubTitle={smallSubTitle}
          />
          <Grid
            container
            spacing={gridSize !== 12 ? 5 : 3}
            style={{ marginTop: "0.75rem" }}
          >
            {card.map((card, index) => {
              return (
                <Grid
                  data-aos={animate && "fade-up"}
                  item
                  xs={12}
                  sm={gridSize}
                  key={`${id}-${index}`}
                >
                  <CardElm
                    card={card}
                    backgroundColor={backgroundColor}
                    animate={animate}
                  />
                </Grid>
              )
            })}
          </Grid>
          <SectionDividor isTop={false} core={section.core} />
        </ContainerWidth>
      </PaddedContainer>
    </CardSection>
  )
}

export default CardGrid
