import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "./Button"
import Icon from "./Icon"
import { Card, Image, SensaiColor } from "../../ts/interfaces"
import ProductPrice from "../products/ProductPrice"
import MarkdownContent from "./MarkdownContent"
import { Link } from "gatsby"

const FeatureImage = styled(props => (
  <GatsbyImage {...props} imgStyle={{ margin: "0 auto" }} />
))`
  margin: 1rem 0 0.25rem 0;
  max-height: 250px;
`

const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IconWrapper = styled.div`
  margin: 0rem 0 1.5rem 0;
`

const IconWrapperInline = styled.span`
  margin: 0 1.5rem 0 0;
  font-size: 0.8rem;
`

const CardDescription = styled.div``

const CardElm = ({
  card,
  animate = false,
  backgroundColor,
}: {
  card: Card
  animate: boolean
  backgroundColor?: SensaiColor
}) => {
  const {
    id,
    title,
    description,
    iconName,
    iconType,
    media,
    button,
    align,
    productPriceDisplay,
    productDisplayEnum,
  } = card
  return (
    <div
      style={{
        textAlign: align && align === "center" ? "center" : "left",
      }}
    >
      {iconName && iconType && align && align === "center" && (
        <IconWrapper>
          <Icon
            iconName={iconName}
            iconType={iconType}
            backgroundColor={backgroundColor}
            brightColor={true}
            size="2x"
          />
        </IconWrapper>
      )}
      {(title || (iconName && iconType)) && (
        <h4>
          {iconName && iconType && (!align || align !== "center") && (
            <IconWrapperInline>
              <Icon
                iconName={iconName}
                iconType={iconType}
                backgroundColor={backgroundColor}
                brightColor={true}
                size="2x"
              />
            </IconWrapperInline>
          )}
          {title}
        </h4>
      )}

      {media && (
        <FeatureImage
          image={
            media &&
            media.imageFile &&
            media.imageFile.childImageSharp.gatsbyImageData
          }
          style={{
            margin:
              title || (iconName && iconType)
                ? "0 0 0.25rem 0"
                : "1rem 0 0.25rem 0",
          }}
          objectFit="contain"
          objectPosition="50% 50%"
          alt={media && media.alternativeText}
        />
      )}
      {description && (
        <DescWrapper>
          <CardDescription>
            <MarkdownContent content={description} animate={animate} />
          </CardDescription>
        </DescWrapper>
      )}
      {productPriceDisplay && (
        <ProductPrice
          priceObj={productPriceDisplay}
          productDisplayEnum={productDisplayEnum}
          small={true}
        />
      )}

      {button && (
        <div style={{ marginTop: "1rem" }}>
          {" "}
          <Button
            key={button.id}
            {...button}
            sectionId={`card_${id}_btn_`}
            id={button.id}
          />
        </div>
      )}
    </div>
  )
}

export default CardElm
