import React from "react"
import styled from "styled-components"
import { SectionTitle } from "../ui/Title"
import PaddedContainer from "../ui/PaddedContainer"
import { Testimonial, SectionCore } from "../../ts/interfaces"
import TestimonialsBasic from "./TestimonialsBasic"
import TestimonialsSplash from "./TestimonialsSplash"

interface TestimonialSection {
  id: string
  testimonials: Testimonial[]
  core: SectionCore
  start: number
  version: "basic" | "splash"
}

const Center = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`

const Testimonials = (section: TestimonialSection) => {
  let { testimonials, start, version } = section
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    backgroundColor,
    animate,
    underlineTitle,
    titleAlign,
    smallSubTitle,
  } = section.core

  start = start <= testimonials.length ? start - 1 : testimonials.length - 1

  return (
    <div>
      <PaddedContainer padding="3rem 1rem 5rem 1rem">
        <Center>
          <SectionTitle
            title={title}
            titleColor={titleColor}
            subtitle={subtitle}
            subtitleColor={subtitleColor}
            animate={animate}
            titleAlign={titleAlign}
            underlineTitle={underlineTitle}
            smallSubTitle={smallSubTitle}
          />
        </Center>
        {version == "basic" && (
          <TestimonialsBasic
            testimonials={testimonials}
            startIndex={start}
            backgroundColor={backgroundColor}
          />
        )}
        {version == "splash" && (
          <TestimonialsSplash
            sectionId={section.id}
            testimonials={testimonials}
            startIndex={start}
            backgroundColor={backgroundColor}
          />
        )}
      </PaddedContainer>
    </div>
  )
}

export default Testimonials
