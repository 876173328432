import React from "react"
import styled from "styled-components"
import Icon from "../ui/Icon"
import PaddedContainer from "../ui/PaddedContainer"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

interface TableProps {
  id: string
  table: {
    name: string
    showTitle: boolean
    rows: {
      title: string
      cells: {
        content: string[]
      }[]
    }[]
    columns: {
      title: string
      highlighted: boolean
    }[]
  }
}

const Grid = styled.div<{ cols?: number; rows?: number; isMobile?: boolean }>`
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${({ cols, isMobile }) =>
    isMobile ? `repeat(${cols - 1}, 1fr)` : `repeat(${cols}, 1fr)`};
  column-gap: 10px;
  column-gap: ${({ isMobile }) => (isMobile ? 0 : "10px")};
  width: ${({ isMobile }) => (isMobile ? "100%" : "75%")};
  overflow-x: scroll;
`

const ColumnHeadingBox = styled.div<{ highlighted?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ highlighted }) => (highlighted ? "3px solid #1d99b8" : "none")};
  border-radius: ${({ highlighted }) => (highlighted ? "5px" : "none")};
  margin-bottom: ${({ highlighted }) => (highlighted ? "5px" : 0)};
  margin-top: ${({ highlighted }) => (highlighted ? "5px" : 0)};
  padding: 5px;
`

const ColumnHeading = styled.span`
  text-align: center;
  font-family: "Rubik";
  font-size: 16px;
  font-weight: 600;
  color: #000;
`

const RowTitle = styled.div``

const Cell = styled.div<{
  highlighted?: boolean
  rows?: number
  idx?: number
  isMobile?: boolean
}>`
  border: ${({ highlighted }) => (highlighted ? "3px solid #1d99b8" : "none")};
  border-bottom: ${({ highlighted }) =>
    highlighted ? "3px solid #1d99b8" : "1px solid #CAC6C6"};
  border-left: ${({ highlighted, isMobile, colIdx }) =>
    !highlighted && isMobile && colIdx !== 1 && "1px solid #CAC6C6"};
  background: #f3f6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #454545;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: ${({ highlighted, isMobile, rows, idx }) =>
    highlighted && !isMobile && rows && idx !== rows - 1 ? "5px" : 0};
`

const RowHeader = styled(Cell)<{ isMobile?: boolean }>`
  font-family: "Rubik";
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
  padding-left: 10px;
  grid-column: ${({ isMobile }) => (isMobile ? "span 3" : "span 1")};
  border-left: none;
`

const TableNameText = styled.span`
  color: #1d99b8bf;
  font-family: "Rubik";
`

const LineBox = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Line = styled.div`
  height: 3px;
  width: 32px;
  background: #cac6c6;
  border-radius: 15px;
`

const getIcon = (name: string, isHighlighted: boolean) => {
  switch (name) {
    case "check":
      return (
        <Icon
          iconName="check"
          iconType="Solid"
          iconColor={isHighlighted ? "#1d99b8" : "#CAC6C6"}
          size={"2x"}
        />
      )
    case "empty":
      return (
        <LineBox>
          <Line />
        </LineBox>
      )
    case "question":
      return (
        <Icon
          iconName="question"
          iconType="Solid"
          iconColor={isHighlighted ? "#1d99b8bf" : "#CAC6C6"}
          size={"2x"}
        />
      )
  }
}

const Table = (section: TableProps) => {
  const { table } = section
  const { name, columns, rows, showTitle } = table
  let highlightedCol: number | null = null
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  let colIdx = 0

  return (
    <PaddedContainer>
      <Grid
        cols={columns.length + 1}
        rows={rows.length + 1}
        isMobile={isMobile}
      >
        {!isMobile && (
          <ColumnHeadingBox>
            {showTitle && <TableNameText>{name}</TableNameText>}
          </ColumnHeadingBox>
        )}
        {columns.map((col, colIdx) => {
          colIdx = colIdx
          const { highlighted } = col
          if (highlighted) highlightedCol = colIdx

          return (
            <ColumnHeadingBox highlighted={highlighted}>
              <ColumnHeading>{col.title}</ColumnHeading>
            </ColumnHeadingBox>
          )
        })}
        {rows.map((row, rowIdx) => {
          return (
            <>
              <RowHeader isMobile={isMobile}>{row.title}</RowHeader>
              {row.cells.map((cell, idx) => {
                const isHighlighted =
                  highlightedCol !== null && idx === highlightedCol
                return (
                  <Cell
                    highlighted={isHighlighted}
                    isMobile={isMobile}
                    idx={rowIdx}
                    rows={rows.length}
                    isMobile={isMobile}
                    colIdx={colIdx}
                  >
                    {/* {cell.content} */}
                    {getIcon(cell.content, isHighlighted)}
                  </Cell>
                )
              })}
            </>
          )
        })}
      </Grid>
    </PaddedContainer>
  )
}

export default Table
