import React, { useState } from "react"
import { navigate } from "gatsby"
import Markdown from "markdown-to-jsx"
import styled from "styled-components"
import axios from "axios"
import { FormZone, SectionCore } from "../../ts/interfaces"
import loadable from "@loadable/component"
import { formatSensaiUrl } from "../../utils/format"
const CareerForm = loadable(() => import("../forms/CareerForm"))
const ContactForm = loadable(() => import("../forms/ContactForm"))

const SuccessMessage = styled(Markdown)`
  text-align: center;
`
const FormSpree = ({ form }: { form: FormZone }) => {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("")
  const { type, successMessage, core } = form

  const submitForm = async (formData: FormData, url: string) => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      setSubmitted(true)
      if (form.successRedirect && form.successRedirect.navItemPage.slug) {
        navigate(formatSensaiUrl(form.successRedirect.navItemPage.slug))
      }
    } catch (error) {
      setError(error)
    }
  }

  return (
    <>
      {form.formTitle && <h3 className="gradientText">{form.formTitle}</h3>}
      {form.formSubTitle && <p>{form.formSubTitle}</p>}
      {!submitted && type === "contact" && (
        <ContactForm
          submitForm={submitForm}
          form={form}
          {...form}
          data-aos={core && core.animate && "fade-up"}
        />
      )}
      {!submitted && type === "career" && (
        <CareerForm
          submitForm={submitForm}
          form={form}
          {...form}
          data-aos={core && core.animate && "fade-up"}
        />
      )}
      <div>
        {loading && <h3>Submitting...</h3>}
        {submitted && successMessage && (
          <SuccessMessage>{successMessage}</SuccessMessage>
        )}
        {submitted && !successMessage && (
          <h3>Thanks! We'll get back to you shortly.</h3>
        )}
        {error && <h3>Something went wrong. Please try again.</h3>}
      </div>
    </>
  )
}

export default FormSpree
