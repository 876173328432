import React, { useEffect } from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Markdown from "markdown-to-jsx"
import PaddedContainer from "../ui/PaddedContainer"
import CountUpNumber from "../ui/CountUpNumber"
import { SectionCore } from "../../ts/interfaces"
import { devices } from "../config/breakpoints"
import { SectionTitle } from "../ui/Title"
import { SectionDividor } from "../ui/line"
import withWidth from "@material-ui/core/withWidth"
import AnimateHeight from "react-animate-height"
import { Container } from "@material-ui/core"
import Icon from "../ui/Icon"

interface Spec {
  id: string
  title: string
  description: string
  countTo: number
  countSuffix: string
  countLabel: string
  countPrefix: string
  strikethrough: string
}
interface SpecGrid {
  id: string
  specCard: Spec[]
  core: SectionCore
  width: string
}

interface Last {
  last: number
}
const SpecTitle = styled.h5`
  margin-bottom: 1rem !important;
  @media ${devices.mobile} {
    line-height: 1.2rem;
    margin-bottom: 0rem !important;
  }
`

const GridItem = styled(Grid)<Last>`
  position: relative;
  display: flex;

  text-align: center;

  justify-content: center;
  @media ${devices.mobile} {
  }
  ${({ last }) =>
    last === 1
      ? ``
      : `:after {
      content: "";
      position: absolute;
      right: 0;
      top:35%;
      height: 40%;
      width: 1px;
      border-right: 1px solid var(--color-grey-2) !important;
    }`};
`

const SpecSection = styled.div`
  text-align: center;
  @media ${devices.mobile} {
  }
  h4 {
    margin: 0;
    @media ${devices.mobile} {
      line-height: 2.5rem;
    }
    .countup-number__count {
      text-transform: none;
      font-family: var(--font-family-header);
      font-weight: 300;
      font-size: 2.5rem;
      color: var(--color-grey-3);
      @media ${devices.mobile} {
        font-size: 2rem;
        line-height: 1.5rem;
      }
    }
  }
  h6 {
    font-weight: 400;

    @media ${devices.mobile} {
      font-size: 1rem;
      line-height: 2rem;
    }
  }
  span,
  p {
    font-size: 1rem;
    @media ${devices.mobile} {
      font-size: 1rem;
    }
  }
`

const MoreLessContainer = styled.div`
  overflow: hidden;
  position: relative;
  transition: height 1s;
  height: 100%;
  padding-bottom: 4rem;
`

const ShowMoreLess = styled.div`
  cursor: pointer;
  margin: 0.5rem;
  &.less {
    position: absolute;
    bottom: 0rem;
    width: 100%;
    z-index: 500;
  }
  font-size: 1.5rem;
  .moreDesc {
    transition: color, font-weight, font-size 0.75s ease;
    font-size: 1.1875rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    font-family: var(--font-family-header);
    font-weight: 700;
  }

  :hover {
    .moreDesc {
      color: var(--color-purple);
      font-weight: 800;
      font-size: 1.25rem;
    }
  }
`

const getCards = (
  minIndex: number,
  maxIndex: number,
  width: string,
  gridSize: number,
  cards: Spec[]
) => {
  return cards.map((spec: Spec, index: number) => {
    if (index > maxIndex || index < minIndex) {
      return null
    }

    const {
      id,
      title,
      description,
      countSuffix,
      strikethrough,
      countTo,
      countLabel,
      countPrefix,
    } = spec

    const lastInRow =
      width === "xs" ||
      index === cards.length - 1 ||
      ((index + 1) * gridSize) % 12 === 0

    return (
      <GridItem last={lastInRow ? 1 : 0} item xs={12} sm={gridSize} key={id}>
        <SpecSection>
          {title && (
            <div style={{ textAlign: !countTo ? "left" : "center" }}>
              <SpecTitle>{title}</SpecTitle>
            </div>
          )}

          {countTo !== undefined && countTo !== null && (
            <CountUpNumber
              end={countTo}
              suffix={countSuffix}
              label={countLabel}
              prefix={countPrefix}
              strikethrough={strikethrough}
            />
          )}
          {description && (
            <div
              style={{
                textAlign: !countTo ? "left" : "center",
                marginTop: !countTo ? "0" : "1rem",
              }}
            >
              <Markdown>{description}</Markdown>
            </div>
          )}
        </SpecSection>
      </GridItem>
    )
  })
}

const CardGrid = (section: SpecGrid) => {
  const { id, specCard, width } = section
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    backgroundColor,
    animate,
    titleAlign,
    underlineTitle,
    smallSubTitle,
  } = section.core
  const gridSize =
    width === "sm"
      ? specCard.length >= 2
        ? 6
        : 12 / specCard.length
      : specCard.length >= 4
      ? 3
      : 12 / specCard.length

  const [expanded, setExpanded] = React.useState(false)
  const [baseHeight, setBaseHeight] = React.useState(250)

  const topRef = React.useRef(null)
  const checkRef = React.useRef(null)

  useEffect(() => {
    if (checkRef?.current) {
      const checkRect = checkRef?.current?.getBoundingClientRect()
      const topRect = topRef?.current?.getBoundingClientRect()
      if (checkRect) {
        const newBaseHeight = checkRect.bottom - topRect.top

        setBaseHeight(newBaseHeight)
      }
    }
  })

  //map initial visible set of cards
  const VisibleCards = getCards(0, 3, width, gridSize, specCard)
  const HiddenCards = getCards(
    4,
    specCard.length - 1,
    width,
    gridSize,
    specCard
  )

  return (
    <PaddedContainer align="center">
      <SectionDividor isTop={true} core={section.core} />
      <SectionTitle
        title={title}
        titleColor={titleColor}
        subtitleColor={subtitleColor}
        subtitle={subtitle}
        animate={animate}
        titleAlign={titleAlign}
        underlineTitle={underlineTitle}
        smallSubTitle={smallSubTitle}
      />
      <AnimateHeight
        style={{
          position: "relative",
          marginTop: "1em",
        }}
        duration={1500}
        height={expanded ? "auto" : baseHeight}
      >
        <MoreLessContainer ref={topRef}>
          <Grid
            container
            spacing={6}
            justifyContent="center"
            style={{ overflow: "visible" }}
          >
            {VisibleCards}
            {!expanded && specCard.length > 4 && (
              <Container>
                <ShowMoreLess
                  ref={checkRef}
                  onClick={e => setExpanded(!expanded)}
                >
                  <div className="moreDesc">More</div>
                  <Icon
                    iconType="Solid"
                    size="lg"
                    brightColor={true}
                    iconName={!expanded ? "chevron-down" : "chevron-up"}
                    backgroundColor={
                      backgroundColor ? backgroundColor : undefined
                    }
                  />
                </ShowMoreLess>
              </Container>
            )}
            {specCard.length <= 4 && (
              <div
                style={{ height: "1px", width: "1000px", position: "relative" }}
                ref={checkRef}
              >
                {" "}
              </div>
            )}
            {HiddenCards}
            {expanded && specCard.length > 4 && (
              <ShowMoreLess
                className="less"
                onClick={e => setExpanded(!expanded)}
              >
                <Icon
                  iconType="Solid"
                  size="lg"
                  brightColor={true}
                  iconName={!expanded ? "chevron-down" : "chevron-up"}
                  backgroundColor={
                    backgroundColor ? backgroundColor : undefined
                  }
                />
                <div className="moreDesc">Less</div>
              </ShowMoreLess>
            )}
          </Grid>
          <SectionDividor isTop={false} core={section.core} />
        </MoreLessContainer>
      </AnimateHeight>
    </PaddedContainer>
  )
}

export default withWidth()(CardGrid)
