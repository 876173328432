import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { FormZone, SectionCore } from "../../ts/interfaces"
import PaddedContainer from "../ui/PaddedContainer"
import FormSpree from "../forms/FormSpree"
import NewsletterSignup from "../forms/Newsletter"
import { SectionTitle } from "../ui/Title"
import { Grid } from "@material-ui/core"
import CardElm from "../ui/Card"
import { SectionDividor } from "../ui/line"

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
}))

const Form = (section: FormZone) => {
  const classes = useStyles()

  const { type, core, columns, titleAlign } = section
  const {
    title,
    titleAlign: coreTitleAlign,
    titleColor,
    subtitle,
    subtitleColor,
    backgroundColor,
    animate,
    underlineTitle,
    smallSubTitle,
  } = section.core || {}

  const card = section.card || []

  const numCards = card.length === 0 ? 1 : card.length + 1

  let gridSize = numCards % 2 === 0 ? 6 : 4
  if (numCards == 4) {
    gridSize = 3
  }
  if (columns) {
    gridSize = Math.floor(12 / columns)
  }

  return (
    type && (
      <PaddedContainer>
        <SectionDividor isTop={true} core={section.core} />

        <SectionTitle
          title={title}
          titleColor={titleColor}
          subtitle={subtitle}
          subtitleColor={subtitleColor}
          animate={animate}
          titleAlign={coreTitleAlign}
          underlineTitle={underlineTitle}
          smallSubTitle={smallSubTitle}
        />
        <Grid
          container
          spacing={gridSize !== 12 ? 5 : 3}
          style={{ marginTop: "0.75rem" }}
        >
          {card.map((card, index) => {
            return (
              <Grid
                data-aos={animate && "fade-up"}
                item
                xs={12}
                sm={gridSize}
                key={`${card.id}-${index}`}
              >
                <CardElm
                  card={card}
                  backgroundColor={backgroundColor}
                  animate={animate}
                />
              </Grid>
            )
          })}
          <Grid data-aos={animate && "fade-up"} item xs={12} sm={gridSize}>
            <PaddedContainer maxWidth="xs" padding="0">
              <div
                className={classes.paper}
                style={{ textAlign: titleAlign ? titleAlign : "left" }}
              >
                {core && core.subtitle && (
                  <Typography component="h3">{core.subtitle}</Typography>
                )}
                {type !== "emailSignUp" && type !== "clinicSignUp" && (
                  <FormSpree
                    form={section}
                    data-aos={core && core.animate && "fade-up"}
                  />
                )}
                {(type === "emailSignUp" || type === "clinicSignUp") && (
                  <NewsletterSignup form={section} compress={false} />
                )}
              </div>
            </PaddedContainer>
          </Grid>
        </Grid>

        <SectionDividor isTop={false} core={section.core} />
      </PaddedContainer>
    )
  )
}

export default Form
