import React from "react"
import styled from "styled-components"
import ReactHtmlParser from "react-html-parser"

import PaddedContainer from "../ui/PaddedContainer"

enum TitleColor {
  blue = "blue",
  pink = "pink",
  gradient = "gradient",
  gradientRed = "gradientRed",
  gradientTeal = "gradientTeal",
  gradientPurple = "gradientPurple",
  gradientGrey = "gradientGrey",
}
interface PageHeaderProps {
  title: string
  subtitle: string
  alignHeader?: "center" | "left" | "right"
  maxWidth?: number
  titleColor?: TitleColor
}

const Heading = styled.h1<{ titleColor?: string }>`
  display: block;
  & sup {
    font-size: xx-small;
    vertical-align: super;
  }
  color: ${({ titleColor }) =>
    titleColor === "blue"
      ? "var(--color-primary)"
      : titleColor === "pink"
      ? "var(--color-hot-pink)"
      : titleColor?.includes("gradient")
      ? "transparent"
      : "initial"};
  background: ${({ titleColor }) =>
    titleColor === "gradient"
      ? "var(--color-gradient)"
      : titleColor === "gradientRed"
      ? "var(--color-gradient-rot)"
      : titleColor === "gradientTeal"
      ? "var(--color-gradient-teal)"
      : titleColor === "gradientPurple"
      ? "var(--color-gradient-purple)"
      : titleColor === "gradientGrey"
      ? "var(--color-gradient-grey)"
      : "initial"};
  background-clip: ${({ titleColor }) =>
    titleColor?.includes("gradient") ? "text" : "initial"};
  -webkit-background-clip: ${({ titleColor }) =>
    titleColor?.includes("gradient") ? "text" : "initial"};
  text-fill-color: ${({ titleColor }) =>
    titleColor?.includes("gradient") ? "transparent" : "initial"};
`

const SubHeading = styled.h4`
  & sup {
    font-size: xx-small;
    vertical-align: super;
  }
`

const ContainerWidth = styled(props => <div {...props} />)`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : "")}
  margin: 0 auto;
`

const PageHeader = (props: PageHeaderProps) => {
  const { title, subtitle, alignHeader, titleColor } = props
  const textAlign = alignHeader || "left"

  return (
    <PaddedContainer padding="2rem 1rem 1rem 1rem">
      <ContainerWidth maxWidth={props.maxWidth}>
        <div style={{ textAlign }}>
          <Heading titleColor={titleColor}>{ReactHtmlParser(title)}</Heading>
          <SubHeading>{ReactHtmlParser(subtitle)}</SubHeading>
        </div>
      </ContainerWidth>
    </PaddedContainer>
  )
}

export default PageHeader
