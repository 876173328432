import React from "react"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Link } from "gatsby"

type SectionStylingProps = {
  paddingTop?: number
  paddingBottom?: number
  marginTop?: number
  marginBottom?: number
  backgroundColor: "white" | "lightGrey"
}

type BannerProps = {
  banner: {
    paddingTop?: number
    paddingBottom?: number
    marginTop?: number
    marginBottom?: number
    backgroundColor: "white" | "lightGrey"
    image: {
      alternativeText: string
      imageFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }
    linkToPage?: {
      slug: string
    }
    linkToLandingPage?: {
      slug: string
    }
    linkToExternalPage?: string
  }
}

const Section = styled.section<SectionStylingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: ${props => props.paddingTop || 0}px;
  padding-bottom: ${props => props.paddingBottom || 0}px;
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  background-color: ${props =>
    props.backgroundColor === "white" ? "#ffffff" : "#d3d3d3"};
`

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease; /* Added transition here */

  ${StyledImageWrapper}:hover & {
    transform: scale(1.01);
  }
`

const Banner = (props: BannerProps) => {
  const {
    paddingTop = 0,
    paddingBottom = 0,
    marginTop = 0,
    marginBottom = 0,
    backgroundColor,
    image,
    linkToPage,
    linkToLandingPage,
    linkToExternalPage,
  } = props.banner
  const isInternalLink = !!(linkToPage || linkToLandingPage)

  const ensureHttps = (url: string): string => {
    if (url.startsWith("https://") || url.startsWith("http://")) {
      return url
    }
    return `https://${url}`
  }
  const getLink = (
    linkToPage?: string,
    linkToExternalPage?: string
  ): string => {
    const link = linkToPage || linkToExternalPage || ""

    if (!link.startsWith("/")) {
      return `/${link}`
    }

    return link
  }

  console.log("**props", props)

  return (
    <Section
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      marginTop={marginTop}
      marginBottom={marginBottom}
      backgroundColor={backgroundColor}
    >
      {isInternalLink ? (
        <Link
          to={getLink(linkToPage?.slug, linkToLandingPage?.slug)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <StyledImageWrapper>
            <StyledImage
              image={image?.imageFile.childImageSharp.gatsbyImageData}
              alt={image.alternativeText}
            />
          </StyledImageWrapper>
        </Link>
      ) : (
        <a
          href={ensureHttps(linkToExternalPage || "")}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <StyledImageWrapper>
            <StyledImage
              image={image?.imageFile.childImageSharp.gatsbyImageData}
              alt={image.alternativeText}
            />
          </StyledImageWrapper>
        </a>
      )}
    </Section>
  )
}

export default Banner
