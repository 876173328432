import React from "react"
import styled from "styled-components"
import Container from "@material-ui/core/Container"
import { ButtonProps, FeaturedVideo, Image, Heading } from "../../ts/interfaces"
import { Button, Buttons } from "../ui/Button"
import { IconButton } from "../ui/IconButton"

import { devices } from "../config/breakpoints"
import { Hidden, useMediaQuery, useTheme } from "@material-ui/core"
import PaddedContainer from "../ui/PaddedContainer"
import FeatureImageVideo from "../ui/FeatureImageVideo"

interface ButtonComponent {
  button: ButtonProps
}
interface HeroSection {
  id: string
  heading: Heading
  subheading: Heading
  backgroundImage?: Image
  mobileBackgroundImage?: Image
  button: ButtonComponent[]
  more?: string
  contentAlign: "center" | "right" | "left"
  displayType: "FullScreen" | "RoundedContainer"
  featuredVideo?: FeaturedVideo
}

const Section = styled.section<{
  display: "center" | "right" | "left"
  displayType: "FullScreen" | "RoundedContainer"
}>`
  background-size: cover;
  ${({ displayType }) =>
    displayType === "FullScreen"
      ? `
        margin-top: 4.5rem;
        height: 35rem;
        position: relative;
        .coverImage{
          left:0;
          right:0;
        }
        @media ${devices.mobilePhone} {
          height: 50vh;
          margin-top: 4.5rem !important;
        }
      `
      : `
    max-height: 1000px;
    margin-top: 8rem;
  `}

  overflow: hidden;

  margin-bottom: 4rem;
  @media (max-width: 800px) {
    .playButton {
      background-size: 6rem !important;
    }
  }
  @media (max-width: 700px) {
    .playButton {
      background-size: 7rem !important;
    }
  }
  @media ${devices.mobilePhone} {
    margin-top: 6rem;
    .playButton {
      background-size: 7rem !important;
      background-position: 50% 55%;
    }
  }
`

const CoverImage = styled.div`
  bottom: 0;
  right: 1rem;
  left: 1rem;
  overflow: hidden;
  height: 100%;
  position: absolute !important;
`

const getFullScreenContentSt = (
  display: "center" | "right" | "left"
): string => {
  let styles = `
    
    height:100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
  `
  if (display === "left") {
    styles += `
    padding-right: 60%; 
    padding-left: 3rem;
    text-align: left;
    `
  } else if (display === "right") {
    styles += `
    padding-left: 60%; 
    padding-right: 3rem;
    text-align: left;
    `
  } else if (display === "center") {
    styles += `
    padding-left: 3rem; 
    padding-right: 3rem;
    text-align: center;
    `
  }
  styles += `
  @media (max-width: 800px) {
    width: 45%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media (max-width: 700px) {
    padding: 0 1rem;
  }
  @media (max-width: 601px) {
    width: 90%;
    padding:0;
  }

  `
  return styles
}

const getRounderCornerContentSt = (
  display: "center" | "right" | "left"
): string => {
  let styles = `
    padding: 16%;
  `
  if (display === "left") {
    styles += `
    padding-right: 60%; 
    padding-left: 3rem;
    text-align: left;
    `
  } else if (display === "right") {
    styles += `
    padding-left: 60%; 
    padding-right: 3rem;
    text-align: left;
    `
  } else if (display === "center") {
    styles += `
    padding-left: 3rem; 
    padding-right: 3rem;
    text-align: center;
    `
  }
  styles += `
  @media (max-width: 800px) {
    width: 45%;
    padding: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media (max-width: 700px) {
    padding: 1.5rem 1rem;
  }
  @media (max-width: 601px) {
    width: 90%;
    min-height: 500px;
  }

  @media ${devices.mobilePhone} {
    min-height: 430px;
  }
  `
  return styles
}

const Content = styled.div<{
  display: "center" | "right" | "left"
  displayType: "FullScreen" | "RoundedContainer"
}>`
  width: 35%;
  /* pointer-events: none; */
  position: relative;
  z-index: 70;

  ${({ display, displayType }) =>
    displayType === "FullScreen"
      ? getFullScreenContentSt(display)
      : getRounderCornerContentSt(display)}
  a {
    margin-top: 1rem;
  }

  @media ${devices.mobilePhone} {
    width: 100%;
    text-align: center;
  }
`

export const Title = styled.h1<{ align?: "center" | "left" | "right" }>`
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.5rem;
  &.white {
    color: var(--color-white);
  }
  text-align: ${({ align }) => align || "left"};
  @media ${devices.mobilePhone} {
    font-size: 1.8rem;
    line-height: 2.2;
    margin-bottom: 0.2rem;
    text-align: center;
  }
`
const Subheading = styled.h2<{ align?: "center" | "left" | "right" }>`
  color: var(--color-black);
  font-weight: 450;
  letter-spacing: 1.1px;
  font-size: 1.1875rem;
  line-height: 1.7rem;
  font-family: var(--font-family-content);
  &.white {
    color: var(--color-white);
  }
  text-align: ${({ align }) => align || "left"};
  @media ${devices.mobilePhone} {
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: center;
  }
`

const More = styled.div`
  position: relative;
  width: 800px;
  bottom: 0;
  z-index: 900;
  margin: 0 0 0 -400px;
  text-align: center;
  border-radius: 100%;
  height: 500px;
  background: linear-gradient(
    90deg,
    var(--color-purple-light) 10%,
    var(--color-purple-dark) 70%
  );

  p {
    position: relative;
    top: 20px;
    color: var(--color-white);
    font-size: 1.1875rem;
    letter-spacing: -1px;
    font-weight: 500;
  }
`

const MoreContainer = styled.div`
  position: absolute;
  z-index: 800;
  bottom: -450px;
  left: 50%;
  right: 0;
  border-radius: 50%;
`

const MoreBtn = styled.div`
  position: absolute;
  margin: 0 0 0 -40px;
  left: 50%;
  top: -3.5rem;
  font-size: 18px;
  @media ${devices.mobilePhone} {
    right: 4.5rem;
    top: -2.5rem;
  }
`

const ButtonContainer = styled.div<{ isMobile: boolean }>`
  display: flex;

  @media ${devices.mobilePhone} {
    justify-content: center;
  }
`

const Hero = (section: HeroSection) => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    window.scrollTo({ left: 0, top: e.pageY, behavior: "smooth" })
  }
  const {
    heading,
    subheading,
    backgroundImage,
    mobileBackgroundImage,
  } = section

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const mobileBgImg = mobileBackgroundImage || backgroundImage
  const coverImage = (
    <>
      <Hidden xsDown>
        <CoverImage className="coverImage">
          <FeatureImageVideo
            featuredImage={backgroundImage}
            featuredVideo={section.featuredVideo}
            playInPlace={true}
            margin="0"
            alt=""
            borderRadius={
              section.displayType !== "RoundedContainer" ? "0" : undefined
            }
            objectPosition={
              section.displayType !== "RoundedContainer" ? "50% 50%" : undefined
            }
          />
        </CoverImage>
      </Hidden>
      <Hidden smUp>
        <CoverImage className="coverImage">
          <FeatureImageVideo
            featuredImage={mobileBgImg}
            featuredVideo={section.featuredVideo}
            margin="0"
            borderRadius={
              section.displayType !== "RoundedContainer" ? "0" : undefined
            }
            playInPlace={true}
            objectPosition={
              section.displayType !== "RoundedContainer" ? "50% 50%" : undefined
            }
          />
        </CoverImage>
      </Hidden>
    </>
  )

  return (
    <Section
      id={`section_${section.id}`}
      className="section hero white"
      display={section.contentAlign}
      displayType={section.displayType}
    >
      {section.displayType === "FullScreen" && coverImage}
      <PaddedContainer padding="0 1rem" height="100%">
        {section.displayType === "RoundedContainer" && coverImage}
        <Container
          style={{
            position: "relative",
            paddingLeft: "0",
            paddingRight: "0",
            height: "100%",
            zIndex: 401,
            // pointerEvents: "none",
          }}
        >
          <Content
            display={section.contentAlign}
            displayType={section.displayType}
          >
            {heading && heading.text && (
              <Title
                className={
                  heading.colour === "gradient"
                    ? "gradientText"
                    : heading.colour
                }
                align={heading.align}
              >
                {heading.text}
              </Title>
            )}
            {subheading && subheading.text && (
              <Subheading
                className={
                  subheading.colour === "gradient"
                    ? "gradientText"
                    : subheading.colour
                }
                align={subheading.align}
              >
                {subheading.text}
              </Subheading>
            )}

            {section.button
              .filter(({ button }) => button)
              .map(button => {
                return (
                  <ButtonContainer isMobile={isMobile}>
                    <Button
                      key={button.button.id}
                      {...button.button}
                      sectionId={section.id}
                      size="hero"
                    />
                  </ButtonContainer>
                )
              })}
          </Content>
        </Container>
      </PaddedContainer>
      {section.more && (
        <MoreContainer>
          <More>
            <MoreBtn>
              {" "}
              <IconButton
                r={60}
                g={153}
                b={184}
                a={1}
                size="md"
                icon="angle-double-down"
                onClick={handleClick}
              />
            </MoreBtn>
            <p>{section.more}</p>
          </More>
        </MoreContainer>
      )}
    </Section>
  )
}
export default Hero
