import React, { FC } from "react"
import Carousel from "react-material-ui-carousel"

interface CarouselProps {
  autoPlay?: boolean
  interval?: number
  animation?: "fade" | "slide" | undefined
  index?: number
  indicators?: boolean
  onChange?: (index: number) => void
}

const ReactCarousel: FC<CarouselProps> = ({
  autoPlay,
  interval,
  animation,
  index,
  indicators,
  children,
  onChange,
}) => {
  return (
    <Carousel
      animation={animation}
      autoPlay={autoPlay}
      interval={interval}
      index={index}
      indicators={indicators}
      navButtonsAlwaysInvisible={true}
      onChange={onChange}
    >
      {children}
    </Carousel>
  )
}

ReactCarousel.defaultProps = {
  autoPlay: true,
  interval: 4000,
  animation: "slide",
  index: 0,
}

export default ReactCarousel
