import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import MarkdownContent from "../ui/MarkdownContent"

type PrivacyOrTerms = "privacyPolicy" | "termsOfService"

interface PrivacyAndTermsSection {
  policyType: PrivacyOrTerms
}

const Section = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 2rem;
`

const PrivacyAndTerms = (section: PrivacyAndTermsSection) => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        privacyAndTerm {
          privacyPolicy
          termsOfService
        }
      }
    }
  `)
  const { policyType } = section
  const { privacyPolicy, termsOfService } = data?.strapi?.privacyAndTerm || ""

  return (
    <Section>
      {policyType === "privacyPolicy" && (
        <div>
          <MarkdownContent animate={false} content={privacyPolicy} />
        </div>
      )}
      {policyType === "termsOfService" && (
        <div>
          {/* Terms! */}
          <MarkdownContent animate={false} content={termsOfService} />
        </div>
      )}
    </Section>
  )
}

export default PrivacyAndTerms
