import React, { useState } from "react"
import styled from "styled-components"
import { SectionTitle } from "../ui/Title"
import PaddedContainer from "../ui/PaddedContainer"

import { Card, SectionCore } from "../../ts/interfaces"

import SimpleContent from "./SimpleContent"
import { SectionDividor } from "../ui/line"

interface InteractiveFeatureSection {
  id: string
  description: string
  cards: Card[]
  core: SectionCore
}

const FeatureWrapper = styled.div`
  & p {
    font-size: 1.1875rem;
    line-height: 2rem;
    margin: 1rem 0;
  }
  margin: 0 -24px;
`

const InteractiveFeatures = (section: InteractiveFeatureSection) => {
  const { description, cards } = section
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    backgroundColor,
    animate,
    titleAlign,
    underlineTitle,
    smallSubTitle,
  } = section.core
  const [index, setIndex] = useState(0)

  return (
    <PaddedContainer>
      <SectionDividor isTop={true} core={section.core} />
      <SectionTitle
        title={title}
        titleColor={titleColor}
        subtitle={subtitle}
        subtitleColor={subtitleColor}
        animate={animate}
        gradient={true}
        titleAlign={titleAlign}
        underlineTitle={underlineTitle}
        smallSubTitle={smallSubTitle}
      />
      {description && <p data-aos={animate && "fade-up"}>{description}</p>}
      {cards.map((card: Card, i) => {
        const { id, title, description, media } = card

        return (
          <FeatureWrapper key={id}>
            <SimpleContent
              id={`SimpleContent-${section.id}`}
              featuredImage={media}
              core={{ animate: false, subtitle: title }}
              content={description}
              align={i % 2 == 0 ? "left" : "right"}
              wideImage={true}
              productPriceDisplay={card.productPriceDisplay}
              productDisplayEnum={card.productDisplayEnum}
              buttons={card.button ? [{ button: card.button }] : []}
            />
          </FeatureWrapper>
        )
      })}
      <SectionDividor isTop={false} core={section.core} />
    </PaddedContainer>
  )
}

export default InteractiveFeatures
