import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import CardGrid from "./sections/CardGrid"
import SpecGrid from "./sections/SpecGrid"
import SimpleContent from "./sections/SimpleContent"
import Testimonials from "./sections/Testimonials"
import Features from "./sections/Features"
import InteractiveFeatures from "./sections/InteractiveFeatures"
import Form from "./sections/Form"
import Steps from "./sections/Steps"
import PageHeader from "./sections/PageHeader"
import EmailConfirm from "./sections/EmailConfirm"
import PrivacyAndTerms from "./sections/PrivacyAndTerms"
import Table from "./sections/Table"
import Membership from "./sections/MembershipSection"
import CarouselSection from "./sections/Carousel"
import Banner from "./sections/Banner"
import { PageSection } from "../ts/interfaces"
import { useInView } from "react-hook-inview"
//import loadable from "@loadable/component"

// const CardGrid = loadable(
//   () => import(/* webpackPrefetch: true */ "./sections/CardGrid")
// )
// const SpecGrid = loadable(
//   () => import(/* webpackPrefetch: true */ "./sections/SpecGrid")
// )
// const SimpleContent = loadable(() => import("./sections/SimpleContent"))
// const Testimonials = loadable(() => import("./sections/Testimonials"))
// const Features = loadable(() => import("./sections/Features"))
// const Steps = loadable(() => import("./sections/Steps"))
// const PageHeader = loadable(() => import("./sections/PageHeader"))
// const InteractiveFeatures = loadable(
//   () => import("./sections/InteractiveFeatures")
// )
// const Form = loadable(() => import("./sections/Form"))

const Anchor = styled.a`
  &:hover,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
    color: initial;
    cursor: initial;
  }
  color: initial;
  cursor: initial;
  &:before {
    display: block;
    content: "";
    height: 78px;
    margin: -78px 0 0;
  }
`

const CoverImage = styled(props => <GatsbyImage alt="" {...props} />)`
  object-fit: cover;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  object-position: 50% 50%;
  position: absolute !important;
  overflow: hidden;
`

const components = {
  CardGrid: CardGrid,
  SpecGrid: SpecGrid,
  SimpleContent: SimpleContent,
  Testimonials: Testimonials,
  Features: Features,
  Form: Form,
  Steps: Steps,
  PageHeader: PageHeader,
  InteractiveFeatures: InteractiveFeatures,
  EmailConfirm: EmailConfirm,
  PrivacyAndTerms: PrivacyAndTerms,
  Table: Table,
  Membership: Membership,
  CarouselSection: CarouselSection,
  Banner: Banner,
}

const Section = ({
  section,
  delayIndex,
}: {
  section: PageSection
  delayIndex?: number
}) => {
  let component: string
  const checkRef = React.useRef(null)
  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [delayed, setDelayed] = useState(delayIndex ? true : false)
  const [displayed, setDisplated] = useState(isVisible)
  const [forceDisplayed, setForceDisplayed] = useState(isVisible)

  let timeout: number
  useEffect(() => {
    if (delayed && delayIndex) {
      if (window && checkRef?.current) {
        const distanceY = window.innerHeight + window.pageYOffset

        if (distanceY - checkRef?.current?.getBoundingClientRect().top > 0) {
          //this item is already in the view port

          setForceDisplayed(true)
          setDelayed(false)
        }
      }

      let delay = 500 + 250 * delayIndex
      delay = delay > 2000 ? 2000 : delay

      timeout = setTimeout(() => {
        setDelayed(false)
      }, delay)
    }
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    setDisplated(displayed || isVisible)
  }, [isVisible])

  switch (section.__typename) {
    case "STRAPI_ComponentZonesCardZone":
      component = "CardGrid"
      break
    case "STRAPI_ComponentZonesSimpleContentZone":
      component = "SimpleContent"
      break
    case "STRAPI_ComponentZonesTestimonialsZone":
      component = "Testimonials"
      break
    case "STRAPI_ComponentZonesFeaturesZone":
      component = "Features"
      break
    case "STRAPI_ComponentZonesInteractiveFeaturesZone":
      component = "InteractiveFeatures"
      break
    case "STRAPI_ComponentZonesSpecZone":
      component = "SpecGrid"
      break
    case "STRAPI_ComponentZonesFormZone":
      component = "Form"
      break
    case "STRAPI_ComponentZonesStepsZone":
      component = "Steps"
      break
    case "STRAPI_ComponentZonesHeaderZone":
      component = "PageHeader"
      break
    case "STRAPI_ComponentZonesEmailConfirmZone":
      component = "EmailConfirm"
      break
    case "STRAPI_ComponentZonesPrivacyAndTermsZone":
      component = "PrivacyAndTerms"
      break
    case "STRAPI_ComponentZonesTableZone":
      component = "Table"
      break
    case "STRAPI_ComponentZonesMembershipZone":
      component = "Membership"
      break
    case "STRAPI_ComponentZonesCarouselZone":
      component = "CarouselSection"
      break
    case "STRAPI_ComponentZonesBannerZone":
      component = "Banner"
      break
    default:
      component = ""
  }

  let { backgroundColor, backgroundImage } = section.core || {}
  if (component === "") {
    return <></>
  }

  const Component = components[component]
  //const Component = loadable(() => import(`./sections/${component}`))

  const sectionClass = "section"

  return (
    <>
      {section?.core?.anchorTag && <Anchor id={section.core.anchorTag} />}

      <section
        id={`section_${section.id}`}
        ref={ref}
        className={
          backgroundColor
            ? `${sectionClass}  ${component} ${backgroundColor}`
            : `${sectionClass}  ${component} white`
        }
        style={{
          position: "relative",
          marginTop: section.core?.marginTop
            ? `${section.core.marginTop}rem`
            : 0,
          marginBottom: section.core?.marginBottom
            ? `${section.core.marginBottom}rem`
            : 0,
        }}
      >
        <span ref={checkRef}></span>
        {backgroundImage &&
          backgroundImage.imageFile &&
          backgroundImage.imageFile.childImageSharp.gatsbyImageData && (
            <CoverImage
              image={
                backgroundImage &&
                backgroundImage.imageFile &&
                backgroundImage.imageFile.childImageSharp.gatsbyImageData
              }
              objectFit="cover"
              objectPosition="50% 50%"
              alt={backgroundImage && backgroundImage.alternativeText}
            />
          )}
        {((forceDisplayed || displayed) && !delayed) || !delayIndex ? (
          <Component {...section} />
        ) : (
          <div style={{ height: "500px", width: "100%" }}></div>
        )}
      </section>
    </>
  )
}

export default Section
