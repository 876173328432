import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import React, { useEffect } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { Image } from "../../ts/interfaces"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import PaddedContainer from "./PaddedContainer"

interface Props {
  slides: Image[]
}

const settings = {
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1500,
  arrows: false,
  dots: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
`

const LogoCarousel = ({ slides }: Props) => {
  return (
    <PaddedContainer align="center">
      <Slider {...settings}>
        {slides.map((slide, index) => {
          const img = slide.imageFile.childImageSharp
            .gatsbyImageData as IGatsbyImageData
          return (
            <Container>
              <GatsbyImage
                image={img}
                alt={slide.alternativeText}
                objectFit="contain"
                style={{
                  width: "150px",
                  height: "150px",
                }}
                imgStyle={{
                  width: "150px",
                  height: "150px",
                }}
                key={index}
              />
            </Container>
          )
        })}
      </Slider>
    </PaddedContainer>
  )
}

export default LogoCarousel
