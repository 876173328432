import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Icon from "../ui/Icon"
import PaddedContainer from "../ui/PaddedContainer"
import Carousel from "../ui/Carousel"
import { Testimonial, SectionCore } from "../../ts/interfaces"

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AuthorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 0.5rem;
  margin-bottom: 0.5em;
`

const StyledAvatarImg = styled(props => <GatsbyImage {...props} />)`
  height: 100px;
  width: 100px;
  border-radius: 50%;
`

const Author = styled.h3`
  margin: 0;
  padding: 0;
  line-height: 30px;
`
const Company = styled.span`
  margin: 0 0 0 0.75em;
  text-transform: none;
  font-family: var(--font-family-content);
  letter-spacing: 0;
  padding: 0;
  line-height: 30px;
  font-size: 0.8em;
`

const Quote = styled.p`
  color: var(--color-black);
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 2rem;
  letter-spacing: 0;
  max-width: 75%;
`
const TestimonialsBasic = ({
  testimonials,
  startIndex,
  backgroundColor,
}: {
  testimonials: Testimonial[]
  startIndex: number
  backgroundColor: string | undefined
}) => {
  return (
    <Carousel autoPlay={false} index={startIndex}>
      {testimonials.map((item: Testimonial) => {
        const { id, avatar, author, company, quote } = item

        return (
          <ItemContainer key={id}>
            {avatar && (
              <StyledAvatarImg
                image={avatar.imageFile.childImageSharp.gatsbyImageData}
                alt={avatar.alternativeText || author}
              />
            )}
            {!avatar && (
              <Icon
                backgroundColor={backgroundColor ? backgroundColor : undefined}
                iconType="Solid"
                iconName="user-circle"
                size="3x"
              />
            )}
            <AuthorContainer>
              <Author>{author} </Author>
              {company && <Company> - &ensp; {company}</Company>}
            </AuthorContainer>
            <Quote>"{quote}"</Quote>
          </ItemContainer>
        )
      })}
    </Carousel>
  )
}

export default TestimonialsBasic
