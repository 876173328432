import React, { useState, useEffect } from "react"
import { FormContextProvider } from "../../contexts/formContext"
import { FormInputState } from "../../utils/validations"
import EmailConfirmationForm from "../auth/EmailConfirmationForm"

const intialFormInput: Record<string, FormInputState> = {
  confirmEmailToken: {
    value: "",
    label: "Confirmation Code",
    validation: [{ type: "required" }, { type: "min", value: 6 }],
  },
}

const EmailConfirmation = props => {
  const [cleanUp, setCleanUp] = useState(false)

  useEffect(() => {
    setCleanUp(false)
    return () => {
      setCleanUp(true)
    }
  }, [])
  return (
    <>
      {!cleanUp && (
        <FormContextProvider initialInputs={intialFormInput}>
          <EmailConfirmationForm {...props} />
        </FormContextProvider>
      )}
    </>
  )
}

export default EmailConfirmation
