import React from "react"
import Markdown from "markdown-to-jsx"
import PaddedContainer from "../ui/PaddedContainer"
import {
  Step,
  ButtonProps,
  SectionCore,
  StepInterface,
} from "../../ts/interfaces"
import { Button, Buttons } from "../ui/Button"
import StepsComponent from "../StepsComponent"
import { SectionTitle } from "../ui/Title"
import { SectionDividor } from "../ui/line"

interface StepsSection {
  id: string
  description: string
  steps: Step[]
  buttons: { button: ButtonProps }[]
  core: SectionCore
}

const Steps = (section: StepsSection) => {
  const { description, buttons, steps } = section
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    backgroundColor,
    animate,
    titleAlign,
    underlineTitle,
    smallSubTitle,
  } = section.core
  const initialSteps: StepInterface[] = steps.map(s => {
    return {
      complete: s.state === "complete",
      active: s.state === "active",
      name: s.title,
      icon: s.iconName,
    }
  })

  return (
    <div
      style={{
        backgroundColor: "var(--color-teal-st)",
      }}
    >
      <PaddedContainer padding="5.5rem 3.5rem">
        <PaddedContainer color="var(--color-white)" borderradius="20px">
          <SectionDividor isTop={true} core={section.core} />
          <SectionTitle
            title={title}
            titleColor={titleColor}
            subtitle={subtitle}
            subtitleColor={subtitleColor}
            animate={animate}
            titleAlign={titleAlign || "left"}
            underlineTitle={underlineTitle}
            smallSubTitle={smallSubTitle}
          />

          <StepsComponent steps={initialSteps} />
          <Markdown>{description}</Markdown>
          {buttons && buttons.length > 0 && (
            <Buttons>
              {buttons.map(b => (
                <Button
                  key={b.button.id}
                  {...b.button}
                  sectionId={`${title}_stesp`.toLowerCase().replace(" ", "")}
                />
              ))}
            </Buttons>
          )}
          <SectionDividor isTop={false} core={section.core} />
        </PaddedContainer>
      </PaddedContainer>
    </div>
  )
}

export default Steps
